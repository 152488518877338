<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-input v-model="options.params.sn" clearable placeholder="订单编号"/>
                </el-form-item>
                <el-form-item>
                    <el-select clearable v-model="options.params.status" placeholder="订单状态筛选">
                        <el-option :key="1" :value="1" label="待支付"></el-option>
                        <el-option :key="2" :value="2" label="已完成"></el-option>
                        <el-option :key="3" :value="3" label="已取消"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="options.params.date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
        </crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: false,
                editBtn: false,
                delBtn: true,
                pager: true,
                addUrl: 'league.JoinOrder.add',
                addPerm: 'league_JoinOrder_add',
                editUrl: 'league.JoinOrder.edit',
                editPerm: 'league_JoinOrder_edit',
                delUrl: 'league.JoinOrder.delete',
                delPerm: 'league_JoinOrder_delete',
                listUrl: 'league.JoinOrder.page',
                formLabelWidth: '100px',
                params: {
                    sn: null,
                    status: null,
                    date: null
                },
                columns: [
                    {prop: "order_sn", label: "订单编号",},
                    {
                        prop: "member_id", label: "下单用户", formatter: row => {
                            return row.member ? row.member.nickname : ''
                        }
                    },
                    {prop: "amount", label: "订单金额",},
                    {
                        prop: "first_id", label: "一级用户", formatter: row => {
                            return row.first ? row.first.nickname : ''
                        }
                    },
                    {prop: "first_amount", label: "一级佣金",},
                    {
                        prop: "second_id", label: "二级用户", formatter: row => {
                            return row.second ? row.second.nickname : ''
                        }
                    },
                    {prop: "second_amount", label: "二级佣金",},
                    {
                        prop: "status", label: "订单状态", formatter: row => {
                            return row.status === 0 ? '待支付' : row.status === 1 ? '已完成' : '已取消'
                        }
                    },
                    {prop: "create_time", label: "下单时间",},
                ],
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
